import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Container,
  Box,
  Typography,
  Link,
} from "@material-ui/core";
import Logo from "../../assets/images/steev-logo.png";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Google from "../../assets/images/Google.png";
import AppStore from "../../assets/images/App-store.png";
import Mobile from "../../assets/images/steev-mobile-side-image.png";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "36px",
    paddingRight: 30,
    color: "#1C1C1E",
    fontFamily: "Helvetica",
    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
    },
  },
  text: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#4F4F4F",
    fontFamily: "Helvetica",
  },
  link: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1C1C1E",
    fontFamily: "Helvetica",
  },
  icon: {
    position: "relative",
    top: "7.5px",
    left: "-2px",
  },
  mediaImg: {
    [theme.breakpoints.down("xs")]: {
      width: "157px",
    },
  },
  mediaImgfirst: {
    marginRight: 16,
    [theme.breakpoints.down("xs")]: {
      width: "170px",
    },
  },
  mediapadding: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
    },
  },
  mediamobile: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  medialogo: {
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  imgSpace: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
}));

export default function CenteredGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Steev Home</title>
        <meta
          name="description"
          content="People come to Steev to listen and participate in thought provoking, inspirational and funny conversations with amazing people."
        />
      </Helmet>
      <Container fixed>
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className={classes.mediapadding} pt={30}>
              <img
                src={Logo}
                className={classes.medialogo}
                alt="logo"
                height="auto"
                width="170px"
              />
              <Box pt={2} />
              <Typography className={classes.heading}>
                Enjoy great conversations with people around the world
              </Typography>
              {/* <Typography className={classes.heading}>

              </Typography> */}
              <Box pt={2} />
              <Typography className={classes.text}>
                People come to Steev to listen and participate in thought
              </Typography>
              <Typography className={classes.text}>
                provoking, inspirational and funny conversations with amazing
              </Typography>
              <Typography className={classes.text}>people.</Typography>
              <Box pt={2} />
              <Typography className={classes.link}>
                <Link href="https://about.steevlive.com/">
                  Learn more about Steev{" "}
                  <span>
                    {" "}
                    <ChevronRightIcon
                      className={classes.icon}
                      fontSize="medium"
                    />{" "}
                  </span>
                </Link>
              </Typography>
              <Box pt={3} />
              <Link href="https://steev.page.link" target="_blank">
                <img
                  className={classes.mediaImgfirst}
                  src={Google}
                  alt="Google"
                  height="auto"
                  width="180px"
                />
              </Link>

              <Link href="https://steev.page.link" target="_blank">
                <img
                  className={classes.mediaImg}
                  src={AppStore}
                  alt="AppStore"
                  height="auto"
                  width="164px"
                />
              </Link>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className={classes.imgSpace} textAlign="right" my={10}>
              <img
                src={Mobile}
                className={classes.mediamobile}
                alt="Mobile"
                height="auto"
                width="444.58px"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
