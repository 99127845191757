import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../assets/images/steev-logo.png";
import { Grid, Box, Avatar, Typography, Container } from "@material-ui/core/";
import axios from "axios";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F2F2",
  },
  backgroundcolor: {
    backgroundColor: "white",
    borderRadius: "16px",
    padding: "10px",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  username: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 700,
    fontFamily: "Helvetica",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  usermail: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 400,
    color: " rgba(28, 28, 30, 0.6)",
    fontFamily: "Helvetica",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  followingnumbr: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 700,
    fontFamily: "Helvetica",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  followingtext: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 700,
    fontFamily: "Helvetica",
    color: " rgba(28, 28, 30, 0.6)",
  },
  floatright: {
    float: "left",
    [theme.breakpoints.down("xs")]: {
      float: "none",
    },
  },

  texttitle: {
    color: "#8A8FA9",
    lineHeight: "20px",
    fontWeight: 700,
    fontFamily: "Helvetica",
    fontSize: "10px",
    textTransform: "uppercase",
  },
  backgroundcolortitle: {
    backgroundColor: "white",
    borderRadius: "16px",
    padding: "30px",
  },
}));

export default function FullWidthGrid({ match }) {
  const classes = useStyles();
  const [state, setstate] = React.useState({
    loading: true,
    data: null,
  });
  React.useEffect(() => {
    axios
      .get(`https://api.steevlive.com/api/v1/user/profile/${match.params.id}`)
      .then((res) =>
        setstate({ ...state, data: res.data.data, loading: false })
      );
  }, []);

  function stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  return (
    <div className={classes.root}>
      {state.loading ? (
        <Box
          height="100vh"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src={Logo} alt="logo" height="auto" width="170.81px" />
        </Box>
      ) : (
        <>
          <Helmet>
            <title>{state.data.profile.name} | Steev</title>
            <meta
              name="description"
              content={
                state.data.profile.bio
                  ? stripHtml(state.data.profile.bio)
                  : `View profile of ${state.data.profile.name} in Steev.`
              }
            />
          </Helmet>
          <Grid container justifyContent="center">
            <Grid item md={6}>
              <Container fixed>
                <Box py={5} textAlign="center">
                  <img src={Logo} alt="logo" height="auto" width="170.81px" />
                </Box>
                <Box className={classes.backgroundcolor}>
                  <Grid container spacing={3}>
                    <Grid item lg={8} xs={7}>
                      <Grid container spacing={8}>
                        <Grid item lg={9} xs={12}>
                          <Box display="flex" alignItems="center">
                            <Avatar
                              alt=" Elon Musk"
                              src={state.data.profile.profilePic}
                              className={classes.large}
                            />
                            <Box ml={2}>
                              <Typography className={classes.username}>
                                {state.data.profile.name}
                              </Typography>
                              <Typography className={classes.usermail}>
                                @{state.data.profile.username}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={4} xs={5}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Box className={classes.floatright} pt={1}>
                            <Typography className={classes.followingnumbr}>
                              {state.data.followingCount}
                            </Typography>
                            <Typography className={classes.usermail}>
                              Following
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box pt={1}>
                            <Typography className={classes.followingnumbr}>
                              {state.data.followersCount}
                            </Typography>
                            <Typography className={classes.usermail}>
                              Follower
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>

                <Box my={3} className={classes.backgroundcolortitle}>
                  <Typography className={classes.texttitle}>about</Typography>
                  <Typography>
                    <Box
                      py={2}
                      fontFamily="Helvetica"
                      lineHeight="22px"
                      fontSize="16px"
                      color="#1C1C1E"
                      dangerouslySetInnerHTML={{
                        __html: state.data.profile.bio,
                      }}
                    />
                  </Typography>
                </Box>
              </Container>
            </Grid>
          </Grid>
        </>
      )}
      <Box py={10} />
    </div>
  );
}
