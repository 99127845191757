import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../assets/images/steev-logo.png";
import Share from "../../assets/images/share.png";
import Copy from "../../assets/images/copy.png";
import Calender from "../../assets/images/calender.png";
import Google from "../../assets/images/Google.png";
import AppStore from "../../assets/images/App-store.png";
import { ICalendar } from "datebook";
import moment from "moment-timezone";
import {
  Grid,
  Box,
  Avatar,
  Typography,
  Container,
  IconButton,
  Link,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import axios from "axios";
import { Helmet } from "react-helmet";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import FacebookIcon from "@material-ui/icons/Facebook";
import { blue } from "@material-ui/core/colors";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import RedditIcon from "@material-ui/icons/Reddit";
import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
} from "react-share";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F2F2",
  },
  backgroundcolor: {
    backgroundColor: "white",
    borderRadius: "16px",
    padding: "20px",
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    marginLeft: "auto",
    marginRight: "auto",
  },
  clockbg: {
    backgroundColor: "rgba(138, 143, 169, 0.2)",
    borderRadius: "8px",
    padding: "3px 10px 3px 10px",
    display: "inline-block",
    height: "24px",
  },
  clockiconcolor: {
    padding: "3px",
    color: "#8A8FA9",
    position: "relative",
    fontSize: "16px",
  },
  datetext: {
    fontSize: "12px",
    position: "relative",
    top: "-6px",
    color: "#8A8FA9",
  },
  borderbg: {
    backgroundColor: "#E4EFF7",
    borderRadius: "18px",
    height: 48,
    width: 48,
  },
  mediatext: {
    [theme.breakpoints.only("md")]: {
      fontSize: "12px",
    },
  },
  medialogo: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  mediaImgfirst: {
    marginRight: 16,
    [theme.breakpoints.down("xs")]: {
      width: "170px",
    },
  },
  mediaImg: {
    [theme.breakpoints.down("xs")]: {
      width: "157px",
    },
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  Dialog: {
    "& .MuiPaper-root": {
      width: 320,
    },
  },
  social: {
    width: "100%",
  },
  cursor: {
    cursor: "pointer",
  },
}));
const emails = ["username@gmail.com", "user02@gmail.com"];

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      className={classes.Dialog}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Share</DialogTitle>
      <List>
        <FacebookShareButton
          className={classes.social}
          url={window.location.href}
        >
          <ListItem button onClick={() => handleListItemClick("sad")}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <FacebookIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Facebook"} />
          </ListItem>
        </FacebookShareButton>
        <TwitterShareButton
          className={classes.social}
          url={window.location.href}
        >
          <ListItem button onClick={() => handleListItemClick("sad")}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <TwitterIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Twitter"} />
          </ListItem>
        </TwitterShareButton>
        <LinkedinShareButton
          className={classes.social}
          url={window.location.href}
        >
          <ListItem button onClick={() => handleListItemClick("sad")}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <LinkedInIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Linkendin"} />
          </ListItem>
        </LinkedinShareButton>
        <RedditShareButton
          className={classes.social}
          url={window.location.href}
        >
          <ListItem button onClick={() => handleListItemClick("sad")}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <RedditIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Reddit"} />
          </ListItem>
        </RedditShareButton>
      </List>
    </Dialog>
  );
}

export default function FullWidthGrid({ match, history }) {
  const classes = useStyles();

  const [state, setstate] = React.useState({
    loading: true,
    hosts: [],
    data: null,
    date: null,
  });
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const generateIcs = () => {
    const config = {
      title: state.data.title,
      description: state.data.description,
      start: new Date(state.data.scheduleDate),
    };

    const icalendar = new ICalendar(config);
    icalendar.download("event.ics");
  };

  const mytimeZone = () => {
    var tzone = moment.tz.guess();
    let dateSplit = state.data.scheduleDate.split("T");
    const ac = moment(dateSplit[0] + state.data.scheduleTime).format(
      "YYYY-MM-DD HH:mm"
    );
    const tzConv = moment.tz(ac, state.data.timeZone);
    const convertedDt = tzConv
      .tz(
        typeof tzone === "Number"
          ? Intl.DateTimeFormat().resolvedOptions().timeZone
          : tzone
      )
      .format("MMM D YYYY  hh:mm A zz"); // mytimeZone  is my zone
    return convertedDt;
  };

  React.useEffect(() => {
    let one = "https://extreme-ip-lookup.com/json/";
    let two = `https://api.steevlive.com/api/v1/host/${match.params.id}`;
    const requestOne = axios.get(one);
    const requestTwo = axios.get(two);

    axios
      .all([requestOne, requestTwo])
      .then(
        axios.spread((...responses) => {
          const responseOne = responses[0].data;
          const responseTwo = responses[1].data;
          const eventData = responseTwo.data;
          const hosts = [];

          if (eventData.userId) {
            hosts.push({
              type: "HOST",
              name: eventData.userId.name,
              profilePic: eventData.userId.profilePic,
              username: eventData.userId.username,
            });
          }

          if (eventData.approvedCoHost) {
            eventData.approvedCoHost.forEach((coHost) => {
              hosts.push({
                type: "CO-HOST",
                name: coHost.name,
                profilePic: coHost.profilePic,
                username: coHost.username,
              });
            });
          }
          setstate((oldState) => ({
            ...oldState,
            data: eventData,
            hosts: hosts,
            loading: false,
            time: `${responseOne.continent}/${responseOne.city}`,
          }));
          // use/access the results
        })
      )
      .catch((errors) => {
        // react on errors.
      });
  }, []);

  return (
    <div className={classes.root}>
      {state.loading ? (
        <Box
          height="100vh"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src={Logo} alt="logo" height="auto" width="170.81px" />
        </Box>
      ) : (
        <>
          <Helmet>
            <title>{state.data.title} | Steev</title>
            <meta name="description" content={state.data.description} />
          </Helmet>
          <Container fixed>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item md={6}>
                <Box className={classes.medialogo} py={5} textAlign="center">
                  <img src={Logo} alt="logo" height="auto" width="170.81px" />
                </Box>

                <Box className={classes.backgroundcolor}>
                  <Box className={classes.clockbg}>
                    <AccessTimeIcon className={classes.clockiconcolor} />
                    <span className={classes.datetext}>{mytimeZone()}</span>
                  </Box>
                  <Typography>
                    <Box
                      py={2}
                      fontWeight="700"
                      fontFamily="Helvetica"
                      lineHeight="30px"
                      fontSize="22px"
                      color="#1C1C1E"
                    >
                      {state.data.title}
                    </Box>
                  </Typography>
                  <Typography>
                    <Box
                      fontFamily="Helvetica"
                      lineHeight="22px"
                      fontSize="16px"
                      color="rgba(28, 28, 30, 0.8)"
                    >
                      {state.data.description}
                    </Box>
                  </Typography>

                  <Box py={2}>
                    <Grid container spacing={3}>
                      {state.hosts.map((value, i) => (
                        <Grid
                          item
                          key={value.name}
                          lg={3}
                          md={3}
                          sm={4}
                          xs={4}
                          className={classes.cursor}
                          onClick={() => history.push(`/${value.username}`)}
                        >
                          <Avatar
                            alt={value.name}
                            src={value.profilePic}
                            className={classes.large}
                          />
                          <Typography>
                            <Box
                              textAlign="center"
                              pt={1}
                              fontFamily="Helvetica"
                              fontWeight="700"
                              lineHeight="20px"
                              fontSize="10px"
                              color="#8A8FA9"
                            >
                              {value.type}
                            </Box>
                          </Typography>
                          <Typography>
                            <Box
                              className={classes.mediatext}
                              textAlign="center"
                              fontFamily="Helvetica"
                              fontWeight="400"
                              lineHeight="20px"
                              fontSize="14px"
                              color="#000000"
                            >
                              {value.name}
                            </Box>
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>

                <Box py={2} textAlign="center">
                  <Grid container>
                    <Grid item xs={4}>
                      <Box textAlign="right">
                        <IconButton
                          className={classes.borderbg}
                          aria-label="delete"
                          onClick={handleClickOpen}
                        >
                          <img
                            src={Share}
                            alt="Share"
                            height="auto"
                            width="20px"
                          />
                        </IconButton>
                        <Typography>
                          <Box
                            pt={1}
                            pr={1}
                            fontFamily="Helvetica"
                            fontWeight="400"
                            lineHeight="18px"
                            fontSize="12px"
                            color="#000000"
                          >
                            Share
                          </Box>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <IconButton
                        className={classes.borderbg}
                        aria-label="delete"
                        onClick={() =>
                          navigator.clipboard.writeText(window.location.href)
                        }
                      >
                        <img
                          src={Copy}
                          alt="Share"
                          height="auto"
                          width="20px"
                        />
                      </IconButton>
                      <Typography>
                        <Box
                          pt={1}
                          fontFamily="Helvetica"
                          fontWeight="400"
                          lineHeight="18px"
                          fontSize="12px"
                          color="#000000"
                        >
                          Copy Link
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Box pl={2} textAlign="left">
                        <IconButton
                          className={classes.borderbg}
                          aria-label="delete"
                          onClick={generateIcs}
                        >
                          <img
                            src={Calender}
                            alt="Share"
                            height="auto"
                            width="20px"
                          />
                        </IconButton>
                      </Box>
                      <Typography>
                        <Box
                          textAlign="left"
                          pt={1}
                          fontFamily="Helvetica"
                          fontWeight="400"
                          lineHeight="18px"
                          fontSize="12px"
                          color="#000000"
                        >
                          Add Calendar
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Typography>
                  <Box
                    textAlign="center"
                    pt={1}
                    fontFamily="Helvetica"
                    fontWeight="700"
                    lineHeight="20px"
                    fontSize="14px"
                    color="#1C1C1E"
                  >
                    Start listening to talks that will make your day happy!
                  </Box>
                </Typography>

                <Typography>
                  <Box
                    textAlign="center"
                    pt={1}
                    fontFamily="Helvetica"
                    fontWeight="400"
                    lineHeight="20px"
                    fontSize="14px"
                    color="#828282"
                  >
                    What are you waiting for? Get the app now.
                  </Box>
                </Typography>

                <Box pt={2} display="flex" justifyContent="center">
                  <Link
                    href={
                      state.data.firelink
                        ? state.data.firelink
                        : "https://steev.page.link"
                    }
                    target="_blank"
                  >
                    <img
                      className={classes.mediaImgfirst}
                      src={Google}
                      alt="Google"
                      height="auto"
                      width="180px"
                    />
                  </Link>
                  <Link
                    href={
                      state.data.firelink
                        ? state.data.firelink
                        : "https://steev.page.link"
                    }
                    target="_blank"
                  >
                    <img
                      className={classes.mediaImg}
                      src={AppStore}
                      alt="AppStore"
                      height="auto"
                      width="164px"
                    />
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
      <Box py={6} />
    </div>
  );
}
