import { ThemeProvider } from "@material-ui/core/styles";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import theme from "./components/theme";
import Home from "../src/views/home";
import Profile from "../src/views/profile";
import Event from "../src/views/event";
function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/:id" component={Profile} />
          <Route exact path="/event/:id" component={Event} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
